
import AuthService from "@/auth/AuthService";
import OJ7090S from "@/service/OJ7090Service";
import ZFunc from  "@/service/ZFunc"
import { Vue } from "vue-class-component";
import zCONST from "@/const";

export default class OJ7090F extends Vue {
    // クラス定義
    AuthS: AuthService = new AuthService();
    OJ7090S: OJ7090S = new OJ7090S();
    ZF: ZFunc = new ZFunc();

    // 検索フィールド変数
    mRefUSERNM: any = "";       // ユーザ名
    mRefYUKOKB: any = 1;

    mUSERKY: any = 0;
    mTOKUKY: any = 0;
    mAREAKY: any = 0;

    // 一覧フィールド変数
    mDgList: any[] = [];            // データグリッドソース
    loadingFlg: boolean = false;    // ロード中フラグ

    // 変数定義
    $cookies: any;    // cookie

    mounted(): void {
        this.fncInit();
    }

    // 初期表示処理
    fncInit(): void {
        this.fncClear();
       
        this.mUSERKY = this.$cookies.get("USERKY");
        //ログイン情報取得
        let wParam = { USERKY: this.mUSERKY };
        this.OJ7090S.fncGetUserInfo(wParam).then((data: any) => {
            this.mTOKUKY = data.TOKUKY;
            this.mAREAKY = data.AREAKY;
            this.fncGetList();
        });
    }
    
    // 初期化処理
    fncClear(): void {
        this.mRefUSERNM = "";
    }

    // ユーザーリスト取得処理
    fncGetList(): void {
        let wParam = { 
             USERNM: this.mRefUSERNM
            ,KEGECD: 0
            ,TOKUKY: this.mTOKUKY
            ,USERKG: this.$cookies.get("KENGEN")
            ,YUKOKB: this.mRefYUKOKB
        };
        this.loadingFlg = true;
        this.OJ7090S.fncGetList(wParam).then((data: any) => {
            this.mDgList = data.dgList;
        }).finally(() => {this.loadingFlg = false;});
    }

    fncClearButton(): void{
        this.mRefUSERNM ="";
        this.mRefYUKOKB = 1;
        this.fncGetList();
    }

    // 詳細表示
    fncShowShosai(pUSERKY: number): void {
        // 詳細画面を新規モードで呼び出す
        const zZ7011F: any = this.$refs["OJ7091F"];
        let wMODEXX: number;
        if (pUSERKY == 0) {
            wMODEXX = zCONST.cMODE_ADD;
        } else {
            wMODEXX = zCONST.cMODE_DISP;
        }

        //ダイアログを表示
        zZ7011F.fncShow(
        {
            MODEXX: wMODEXX,
            USERKY: pUSERKY,
            AREAKY: this.mAREAKY,
            TOKUKY: this.mTOKUKY,
        },
        () => {
            //登録成功
            this.fncGetList();
        }
        );
    }
}
